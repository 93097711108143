<template>
  <div
    class="v-arora-input v-mb-sm"
    v-form-validator="{
      Disabled: disabled,
      Form: validatorForm,
      Value: phoneInput.replace(/\D/g, ''),
      Required: required,
      Validations: ['phone'],
      MinLength: phoneLength,
      MaxLength: phoneLength
    }"
  >
    <input
      :id="uid"
      :aria-label="label.toString()"
      :aria-valuemax="phoneLength"
      :aria-valuemin="phoneLength"
      :class="{ disabled: disabled }"
      :data-test-id="dataTestId"
      :disabled="disabled"
      :inert="disabled"
      :maxlength="phoneMask.length"
      autocomplete="tel"
      placeholder="&nbsp;"
      type="tel"
      v-maska="options"
      v-model="phoneInput"
      @keyup.enter="onEnterProxy()"
    />
    <span class="v-arora-input__label">
      <slot name="label">
        <span
          class="v-pointer-events-none"
          v-html="label"
        />
      </slot>
      <span
        v-if="required"
        class="v-required"
      />
    </span>
    <span class="v-arora-input__right-end">
      <slot name="right-end" />
    </span>
  </div>
</template>

<script setup lang="ts">
import type { MaskaDetail } from 'maska'

import { ISO, vFormValidator, type VInputType } from '@arora/common'
import { vMaska } from 'maska/vue'

const {
  focusOnMount,
  phone = null,
  onEnter = () => {
    /* do nothing by default */
  },
  disabled = false,
  required = false
} = defineProps<
  VInputType & {
    validatorForm: string
    phone?: string | null
    onEnter?: () => void
  }
>()
const emit = defineEmits(['update:phone'])

const { phoneMask, phoneLength } = usePhoneNumber()
const appConfig = useAppConfig()

const uid = useId()

const phoneInput = ref<string>('')

const options = reactive({
  preProcess: (value: string) => {
    if (appConfig.VueSettingsPreRun.PhoneCountry === ISO.RussianFederation)
      return value.startsWith('8') ? `7${value.substring(1)}` : value

    return value
  },
  mask: phoneMask.value,
  onMaska: (detail: MaskaDetail) => {
    if (detail.completed) {
      const maskValue = phoneMask.value.replace(/\D/g, '')
      emit('update:phone', maskValue + detail.unmasked)
    }
  }
})

onMounted(() => {
  if (phone) phoneInput.value = phone
  if (focusOnMount) document.querySelector<HTMLInputElement>(`#${uid}`)?.focus()
})

watch(
  () => phone,
  (newValue, oldValue) => {
    if (newValue && oldValue !== newValue && newValue !== phoneInput.value) phoneInput.value = newValue
  }
)

function onEnterProxy(): void {
  if (onEnter && phone?.replace(/\D/g, '').length === phoneMask.value.length) onEnter()
}
</script>
